import React from "react";
import "./Addtespaycard.css";
import pay1 from "../assets/img/pay1.png";
import pay2 from "../assets/img/pay2.png";
import pay4 from "../assets/img/pay4.png";
import pay5 from "../assets/img/pay5.png";
import pay6 from "../assets/img/missionbg.png"

function Addtespaycard() {
  return (
    <div className='addtespaycardcnt'>
      <h1 className='addtespaycardtitle'>
        Add Tespay Card to your digital wallet
      </h1>
      <p className='addtespaycardparagraph'>
        Your digital wallet, made even more convenient. Spend your wallet
        balance using the all new Tespay Card.
      </p>

      <div className='addtespaycardsec'>
        <div className='addtespaycardsecitems'>
          <img src={pay1} alt='' className='addtespaytag' />
          <h3 className='addtespaytitle'>Apple pay</h3>
          <p className='addtespaypara'>
            6 seconds - that's all it takes to send money. That's 1/10th a
            minute - it really is that fast!
          </p>
          <img src={pay4} alt='' className='addtespayimg' />
        </div>
        <div className='addtespaycardsecitems'>
          <img src={pay2} alt='' className='addtespaytag' />
          <h3 className='addtespaytitle'>Google pay</h3>
          <p className='addtespaypara'>
            6 seconds - that's all it takes to send money. That's 1/10th a
            minute - it really is that fast!
          </p>
          <img src={pay5} alt='' className='addtespayimg' />
        </div>
      </div>

      <div className="addtespayanytime">
        <div className="tespayanytimeleft">
            <h1 className="tespayanytimetitle">Pay anyone anytime</h1>
            <p className="tespayanytimeparagraph">With Tes Pay, settling up is a breeze. Send money to anyone in seconds and carry on with your day!</p>
        </div>
        <img src={pay6}f width={250} alt="" className="tespayanytimeright" />
      </div>
    </div>
  );
}

export default Addtespaycard;
