import React from "react";
import "./Funtionsimple.css";
import sr1 from "../assets/img/sr1.png";
import sr2 from "../assets/img/sr2.png";
import sr3 from "../assets/img/sr3.png";
import sr4 from "../assets/img/sr4.png";
import sr5 from "../assets/img/sr5.png";
import sr6 from "../assets/img/sr6.png";
import sr7 from "../assets/img/sr7.png";

function Funtionsimple() {
    let incre = 0;
  const functionsimplelistleft = [
    {
      id: 1,
      title: "A card you love",
      text: "Free. Functional, But most importantly, beautifyl! Own the coolest virtual card you can get",
      img: sr1,
    },
    
    {
      id: 3,
      title: "No tokens or long lines",
      text: "Get your card and manage it using the app, all from the confort of your home",
      img: sr3,
    },
   
    {
      id: 5,
      title: "Your security at your fingertips",
      text: "Control your card spend limit, freeze or unfreeze your card, deactivate your card - all right from the app!",
      img: sr5,
    },
    {
      id: 7,
      title: "Your finances, made easy",
      text: "University fees. Home expenses. Birthday fund. Organize your spending by keeping multiple cards for your financial needs and goals",
      img: sr7,
    },
  ];
  const functionsimplelistright = [
    
    {
      id: 2,
      title: "Stree-free payments",
      text: "Forget about forgetting your card! Compatible with Apple Pay and Google Pay - make easy payments straight from your phone",
      img: sr2,
    },
    
    {
      id: 4,
      title: "Spend money, make money",
      text: "No strings attached - earn cashback on your payments. Ka-ching!",
      img: sr4,
    },
  
    {
      id: 6,
      title: "A card fit for all",
      text: "No restriction on your minimum salary or account balance!",
      img: sr6,
    },
    
  ];

  return (
    <div className="fuctionalcnt">
        <h1 className="functionalcnttop">
            Fuctional. Simple.
        </h1>
        <div className="functionalcntlist">
            <div className="functinalcntlistleft">
                {functionsimplelistleft.map((flist, index) => (
                    <div className="functionallistitem" key={index}>
                        <img src={flist.img} alt="" />
                        <h2 className="functinalistitemtitle">{flist.title}</h2>
                        <p className="functinalistitemtext">
                            {flist.text}
                        </p>
                    </div>
                )) }
            </div>
            <div className="functinalcntlistleft">
                {functionsimplelistright.map((flist, index) => (
                    <div className="functionallistitem" key={index}>
                        <img src={flist.img} alt="" />
                        <h2 className="functinalistitemtitle">{flist.title}</h2>
                        <p className="functinalistitemtext">
                            {flist.text}
                        </p>
                    </div>
                )) }
            </div>
        </div>
    </div>
  )
}

export default Funtionsimple;
