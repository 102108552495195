import React from "react";
import { intagramicon, linkedinicon, twittericon } from "../utilities/tools";
import "./Footer.css"

function Footer() {
  return (
    <div className='footer_body'>
      <div className='footer_cnt'>
        <div className='footercnttop'>
          <div className='footercnttopitems'>
            <h1 className='footercnttopitemslogo'>Tespay</h1>
            <div className='footersocialinks'>
              {linkedinicon} {twittericon} {intagramicon}
            </div>
          </div>
          <div className='footercnttopitems'>
            <p className='footercnttoplinkheader'>Products</p>
            <p className='footercntlinks'>Recurring Payments</p>
            <p className='footercntlinks'>Instant Bank Pay</p>
            <p className='footercntlinks'>International Payments</p>
            <p className='footercntlinks'>One-off Payments</p>
          </div>
          <div className='footercnttopitems'>
            <p className='footercnttoplinkheader'>Resources</p>
            <p className='footercntlinks'>Customer Hub</p>
            <p className='footercntlinks'>Guide</p>
            <p className='footercntlinks'>API Documentation</p>
            <p className='footercntlinks'>FAQ</p>
            <p className='footercntlinks'>Security</p>
          </div>
          <div className='footercnttopitems'>
            <p className='footercnttoplinkheader'>Company</p>
            <p className='footercntlinks'>About Tespay</p>
            <p className='footercntlinks'>Careers</p>
            <p className='footercntlinks'>News & Press</p>
            <p className='footercntlinks'>Blog</p>
          </div>
          <div className='footercnttopitems'>
            <p className='footercnttoplinkheader'>Contact Us</p>
            <p className='footerextrahead'>Sales</p>
            <p className='footercntlinks'>Contact Sales</p>
            <p className='footercntlinks'>+23456789000</p>
            <p className='footerextrahead'>Support</p>
            <p className='footercntlinks'>Request Support</p>
            <p className='footercntlinks'>+23456789000</p>
          </div>
        </div>
        <div className='footerbottom'>
          <div className='footerbottomleft'>
            <p className='footerbottomcopy'>
              © <span>2023</span> Tespay, Inc. All right reserved
            </p>
          </div>

          <div className='footerbotomright'>
            <p className='footerbottomrightitems'>Privacy Policy</p>
            <p className='footerbottomrightitems'>Terms & Conditions</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
