import React, { useState } from "react";
import "./Navbar.css";
import { menuicon, closemenu } from "../utilities/tools";
import logo from "../assets/img/logo.svg";

function Navbar() {
  const [showmob, setshowmob] = useState(false);

  return (
    <div className='navbar_cntt'>
      <div className='navbar_body'>
        <div className='navbarleft'>
          <img src={logo} alt='' />
          <div className='navbarmiddle'>
            <a href='#' className='navbarmiddlelink'>
              Why Tespay
            </a>
            <a href='#' className='navbarmiddlelink'>
              Products
            </a>
            <a href='#' className='navbarmiddlelink'>
              Company
            </a>
            <a href='#' className='navbarmiddlelink'>
              Resources
            </a>
          </div>
        </div>

        <div className='navbarright'>
          <button className='menuicon' onClick={() => setshowmob(!showmob)}>
            {showmob ? (
              <div className=''>{closemenu}</div>
            ) : (
              <div className=''>{menuicon}</div>
            )}
          </button>
          <button className='authsign'>Get Started</button>
        </div>
      </div>
      {showmob && (
        <>
          {/* <div className='doverlay' onClick={() => setshowmob(false)} /> */}
          <div className='navbarbottom'>
            <div className='navbarbottomlinks'>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("home");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Why Tespay
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("products");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Products
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("company");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Company
              </p>
              <p
                className='navbarmiddlelink'
                onClick={() => {
                  const element = document.getElementById("resources");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  setshowmob(false);
                }}
              >
                Resources
              </p>
            </div>

            <div className='navbarbottombtns'>
              <button className='authsignn'>Get Started</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Navbar;
