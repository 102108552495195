import React from "react";
import "./Hero.css";
import heroimg from "../assets/img/hero.png";
import j1 from "../assets/svg/j1.svg";
import j2 from "../assets/svg/j2.svg";
import j3 from "../assets/svg/j3.svg";
import j4 from "../assets/svg/j4.svg";
import googleimg from "../assets/img/google-play-badge.png";
import appleimg from "../assets/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"

function Hero() {
  return (
    <div className='hero_cnt'>
      <div className='heroleft'>
        {/* <div className='heroleftag'>
          <p className='herolefttagtext'>Simple and Secure Payments</p>
        </div> */}
        <h1 className='heroleftitle'><span>Spend</span>  with Tespay</h1>
        <p className='heroleftparagraph'>
          Your digital wallet, made even more convenient. Spend your wallet balance using the all new Tespay Card, compatible with Apple Pay and Google Pay.
        </p>

      </div>
      <div className='heroright'>
        <img src={heroimg} alt='' />
      </div>
    </div>
  );
}

export default Hero;
