import Addtespaycard from "./components/Addtespaycard";
import Footer from "./components/Footer";
import Funtionsimple from "./components/Funtionsimple";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Sectionfive from "./components/Sectionfive";
import Sectionfour from "./components/Sectionfour";
import Sectionone from "./components/Sectionone";
import Sectionthree from "./components/Sectionthree";
import Sectiontwo from "./components/Sectiontwo";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      {/* <Sectionone />
      <Sectiontwo />
      <Sectionthree />
      <Sectionfour />
      <Sectionfive /> */}
      <Funtionsimple />
      <Addtespaycard />
      <Footer />
    </>
  );
}

export default App;
